/* eslint-disable camelcase */
import {
  Button,
  Input,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  CancelablePromise,
  SidebarStage,
  StageType,
  WorkflowEditorService,
  WorkflowStageDetail,
  WorkflowStageUpdates,
} from 'api-clients/monolith';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Error } from 'components/Error';
import { addMessageAction } from 'containers/FlashMessage/actions';
import {
  StateOption,
  stateOptions,
} from 'containers/WorkflowEditor/components/WorkflowEditorContent/StageDetail/constants';
import { useAvailableStages } from 'containers/WorkflowEditor/hooks/useAvailableStages';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import useForm from 'hooks/useForm';

import { messages as stageColumnHeaderMessages } from '../messages';
import {
  SelectOption,
  useGetStagePlacementOptions,
} from '../useGetStagePlacementOptions';
import { useGetStageTypeOptions } from '../useGetStageTypeOptions';
import { messages } from './messages';
import { StageDescriptionTextMap } from './StageDescription';
import { useStyles } from './styles';

interface FormValues {
  title?: string;
  stage_type?: StageType;
  position: number;
  state?: string;
}

export interface NewStageModalProps {
  stages: SidebarStage[];
  open: boolean;
  onClose: () => void;
  refetchStages: () => void;
}

export const NewStageModal: FC<NewStageModalProps> = ({
  stages,
  open,
  onClose,
  refetchStages,
}) => {
  const intl = useIntl();
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { accountSlug, funnelSlug } = useParams<{
    accountSlug: string;
    funnelSlug: string;
  }>();

  const { result: availableStagesResult } = useAvailableStages({ funnelSlug });

  const stageTypeOptions = useGetStageTypeOptions({
    stageTypes:
      availableStagesResult.status === 'ready'
        ? availableStagesResult.data.stage_types.map(stage => {
            if (
              stage.name === 'Alpharun Partner Stage' ||
              stage.name === 'AI Interview Partner Stage'
            ) {
              return { ...stage, name: 'AI Interview' };
            }
            return stage;
          })
        : [],
  }).sort((a, b) => a.label.localeCompare(b.label));

  const stagePlacementOptions = useGetStagePlacementOptions({ stages });

  const { result: createStageResult, mutation: createStage } =
    useApiServiceMutation<
      WorkflowStageDetail,
      (
        funnelSlug: string,
        requestBody?: WorkflowStageUpdates,
      ) => CancelablePromise<WorkflowStageDetail>,
      { errors: Record<string, Array<string>> }
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      WorkflowEditorService.postInternalApiWorkflowEditorFunnelsStages,
      {
        onSuccess: (data: WorkflowStageDetail) => {
          onClose();
          refetchStages();
          history.push(
            `/${accountSlug}/openings/${funnelSlug}/workflow/${
              data.slug ?? ''
            }`,
          );
          dispatch(
            addMessageAction(
              intl.formatMessage(
                stageColumnHeaderMessages.stageCreatedSuccessfully,
              ),
              'success',
            ),
          );
        },
      },
    );

  function validate(values: FormValues) {
    const errors: Partial<Record<keyof FormValues, string>> = {};
    if (!values.title?.trim()) {
      errors.title = intl.formatMessage(messages.stageRequired);
    }
    if (!values.stage_type) {
      errors.stage_type = intl.formatMessage(messages.selectAStageType);
    }
    if (values.stage_type === 'W4StateFormStage' && !values.state) {
      errors.state = intl.formatMessage(messages.selectAState);
    }
    if (!values.position) {
      errors.position = intl.formatMessage(messages.selectAStage);
    }
    return errors;
  }

  const onSubmitStage = (formValues: FormValues) => {
    const { stage_type, title, position, state } = formValues;

    createStage(funnelSlug, {
      workflow_stage: {
        stage: {
          type: stage_type,
          title,
          position: position > 0 ? position + 1 : position,
          ...(state ? { state } : {}),
        },
      },
    });
  };

  const { values, handleChange, handleSubmit, errors } = useForm<FormValues>(
    onSubmitStage,
    validate,
    {
      stage_type: 'DataCollectionStage',
      position: -1,
    },
  );

  const nonFederalStates = stateOptions.filter(state => {
    return !state.usesFederalForm;
  });

  const selectedState = stateOptions.find(
    option => option.value === values.state,
  );

  const createStageResultErrors =
    createStageResult.status === 'error' && createStageResult?.error?.errors;

  const isLoading =
    createStageResult.isLoading || availableStagesResult.isLoading;

  return (
    <Modal
      ariaLabelledBy="new-stage-modal-header"
      open={open}
      onClose={onClose}
      fullScreenOnMobile
      maxWidth={496}
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader
          onClose={onClose}
          ariaLabelledBy="new-stage-modal-header"
          showIcon={false}
        >
          <FormattedMessage {...messages.header} />
        </ModalHeader>
        <ModalContent>
          {availableStagesResult.isLoading && (
            <Loader fullSection size="2rem" />
          )}
          {availableStagesResult.isError && (
            <Typography variant="body2" className={styles.descriptionText}>
              <FormattedMessage {...messages.stageLoadError} />
            </Typography>
          )}
          {availableStagesResult.status === 'ready' && (
            <Grid container>
              <Grid item xs={12}>
                <Input
                  aria-label={intl.formatMessage(messages.title)}
                  label={intl.formatMessage(messages.title)}
                  value={values.title}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleChange({ title: event.target.value })
                  }
                  error={Boolean(errors.title ?? createStageResultErrors)}
                  required
                />
                <Error
                  error={
                    errors.title ||
                    (createStageResultErrors && createStageResultErrors[0])
                  }
                  align="right"
                />
              </Grid>

              <Grid item xs={12} className={styles.inputTopPadding}>
                <StyledReactSelect
                  aria-label={intl.formatMessage(messages.stageType)}
                  label={intl.formatMessage(messages.stageType)}
                  options={stageTypeOptions}
                  onChange={(option: SelectOption) => {
                    handleChange({ stage_type: option.value as StageType });
                  }}
                  value={stageTypeOptions.find(
                    option => option.value === values.stage_type,
                  )}
                  isSearchable={false}
                  error={Boolean(errors.stage_type)}
                  required
                />
                <Error error={errors.stage_type} align="right" />
              </Grid>

              {values.stage_type &&
                Boolean(StageDescriptionTextMap[values.stage_type]) && (
                  <Grid item xs={12} className={styles.descriptionWrapper}>
                    <Typography
                      variant="body2"
                      className={styles.descriptionText}
                    >
                      {StageDescriptionTextMap[values.stage_type]}
                    </Typography>
                  </Grid>
                )}

              {values.stage_type === 'W4StateFormStage' && (
                <Grid item xs={12} className={styles.inputTopPadding}>
                  <StyledReactSelect
                    label={intl.formatMessage(messages.state)}
                    options={nonFederalStates}
                    getOptionLabel={(option: StateOption) => {
                      return option.label;
                    }}
                    onChange={(option: SelectOption) => {
                      handleChange({ state: option.value as string });
                    }}
                    value={selectedState}
                    isSearchable
                    error={Boolean(errors.state)}
                    required
                  />
                  <Error error={errors.state} align="right" />
                </Grid>
              )}

              <Grid item xs={12} className={styles.inputTopPadding}>
                <StyledReactSelect
                  label={intl.formatMessage(messages.stagePlacement)}
                  options={stagePlacementOptions}
                  onChange={(option: SelectOption) => {
                    handleChange({ position: Number(option.value) });
                  }}
                  value={stagePlacementOptions.find(
                    option => option.value === values.position,
                  )}
                  isSearchable={false}
                  error={Boolean(errors.position)}
                />
                <Error error={errors.position} align="right" />
              </Grid>
            </Grid>
          )}
        </ModalContent>
        <ModalFooter>
          <Button type="secondary" onClick={onClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            data-testid="new-stage-save-button"
            submit
            disabled={isLoading || availableStagesResult.isError}
            isLoading={isLoading}
          >
            <FormattedMessage {...messages.addStage} />
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
