import { Grid } from '@material-ui/core';
import {
  WorkflowPartnerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  makeIsCandidateAiEnabled,
  makeIsDelaySendingWebhookEnabled,
  makeIsUpsFunctionalityEnabled,
} from 'containers/Auth_old/selectors';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import {
  IS_HIDDEN_FROM_PORTAL,
  SHOW_I9_COMPLIANCE_CENTER_AGENT,
} from './constants';
import { DelaySendingWebhookSettings } from './DelaySendingWebhookSettings/DelaySendingWebhookSettings';
import { messages } from './messages';
import { PartnerIntegrationConnectedCard } from './PartnerIntegrationConnectedCard/PartnerIntegrationConnectedCard';
import { PartnerIntegrationNotConnectedCard } from './PartnerIntegrationNotConnectedCard/PartnerIntegrationNotConnectedCard';
import { useStyles } from './styles';

interface ParamProps {
  accountSlug: string;
}

interface PartnerIntegrationCardProps {
  stage: WorkflowPartnerStage;
  workflowId?: string;
}

export const PartnerIntegrationCard: VFC<PartnerIntegrationCardProps> = ({
  stage,
  workflowId,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<ParamProps>();
  const { setStage } = useContext(StageContext);
  const {
    advanced_settings: {
      is_hidden_from_portal: isHiddenFromPortal,
      show_i9_compliance_center_agent: showI9ComplianceCenterAgent,
    },
    additional_info: additionalInfo,
  } = stage;
  const isCandidateAiEnabled = useSelector(makeIsCandidateAiEnabled());

  const isUpsFunctionalityEnabled = useSelector(
    makeIsUpsFunctionalityEnabled(),
  );

  const partnerIntegrationConfigured =
    additionalInfo.partner_integration_configured;
  const isDelaySendingWebhookEnabled = useSelector(
    makeIsDelaySendingWebhookEnabled(),
  );
  const showWebhookDelayToggle =
    workflowId &&
    isDelaySendingWebhookEnabled &&
    !!additionalInfo.webhook_delay_settings;
  const partnerName = additionalInfo.partner_name ?? 'partner';

  let manageIntegrationHref;
  if (partnerName === 'Alpharun' || partnerName === 'AI Interview') {
    manageIntegrationHref = `/${accountSlug}/ai_interviewers`;
  } else {
    manageIntegrationHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/integrations/screening`;
  }

  const onToggleIsHiddenFromPortal = () => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.advanced_settings) {
          draftStage.advanced_settings.is_hidden_from_portal =
            !draftStage.advanced_settings.is_hidden_from_portal;
        }
      }),
    );
  };

  const onToggleshowI9ComplianceCenterAgent = () => {
    setStage(
      produce((draftStage: WorkflowStageDetail) => {
        if (draftStage.advanced_settings) {
          draftStage.advanced_settings.show_i9_compliance_center_agent =
            !draftStage.advanced_settings.show_i9_compliance_center_agent;
        }
      }),
    );
  };

  return (
    <StageSettingCard
      title={intl.formatMessage(messages.integrationName, {
        partnerName: stage.title,
      })}
      variant="button"
      buttonText={intl.formatMessage(messages.manageIntegration)}
      buttonTextColor="primary"
      href={manageIntegrationHref}
      target="_blank"
    >
      {partnerIntegrationConfigured ? (
        <PartnerIntegrationConnectedCard stage={stage} />
      ) : (
        <PartnerIntegrationNotConnectedCard partnerName={partnerName} />
      )}
      <Grid className={styles.grid}>
        <SettingSwitch
          dataKey={IS_HIDDEN_FROM_PORTAL}
          checked={!isHiddenFromPortal}
          onChange={onToggleIsHiddenFromPortal}
          label={intl.formatMessage(messages.showStageInApplicantPortal)}
        />
        {isCandidateAiEnabled && isUpsFunctionalityEnabled && (
          <SettingSwitch
            dataKey={SHOW_I9_COMPLIANCE_CENTER_AGENT}
            checked={!!showI9ComplianceCenterAgent}
            onChange={onToggleshowI9ComplianceCenterAgent}
            label={intl.formatMessage(messages.enableI9ComplianceCenterWidget)}
          />
        )}
      </Grid>
      {showWebhookDelayToggle && (
        <Grid className={styles.grid}>
          <DelaySendingWebhookSettings stage={stage} workflowId={workflowId} />
        </Grid>
      )}
    </StageSettingCard>
  );
};
